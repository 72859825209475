import React, { Fragment, useCallback } from "react"
import { Button, Badge } from "@shopify/polaris"
import { navigate } from "gatsby"
import formatLastSavedDate from "../helpers/formatLastSavedDate"
import OfferStatusButton from "../pages/app/offers/common/offerStatusButton/offerStatusButton"
const DocStatus = (props) => {
  const {
    isEditing,
    location,
    name,
    updatedAt,
    handleFormSubmit,
    isSubmitting,
    hasUnsavedChanges,
    token,
    shop,
    firebase,
    state,
    setState,
    domain,
    fullWidth,
    title,
  } = props
  const offerMarkColor = state.enabled ? "#008060" : "#717171"

  const handleGoToShipping = useCallback(() => {
    window.open(
      `https://${domain}/admin/settings/shipping#local-pickup`,
      "_blank"
    )
  }, [domain])
  const handleGoToPayment = useCallback(() => {
    window.open(
      `https://${domain}/admin/settings/payments/customizations`,
      "_blank"
    )
  }, [domain])
  return isEditing ? (
    <Fragment>
      <div
        style={
          fullWidth
            ? { padding: "10px 0", paddingRight: "3rem" }
            : { padding: "10px 0", width: "550px", paddingRight: "3rem" }
        }
      >
        <div className="Polaris-Page-Header__Row">
          <div
            className="Polaris-Page-Header__BreadcrumbWrapper"
            onClick={() => {
              navigate("/app/customizations", {
                state: location.state,
                replace: true,
              })
            }}
          >
            <nav role="navigation">
              <a
                data-polaris-unstyled="true"
                className="Polaris-Breadcrumbs__Breadcrumb"
              >
                <span className="Polaris-Breadcrumbs__Icon">
                  <span className="Polaris-Icon">
                    <span className="Polaris-VisuallyHidden"></span>
                    <svg
                      viewBox="0 0 20 20"
                      className="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2z"></path>
                    </svg>
                  </span>
                </span>
                <span className="Polaris-VisuallyHidden"></span>
              </a>
            </nav>
          </div>
          <div className="Polaris-Page-Header__TitleWrapper">
            <div className="OfferHeaderContainer">
              <div
                className="Polaris-Header-Title__TitleAndSubtitleWrapper"
                style={{ width: "100%" }}
              >
                <div className="OfferHeaderContainer">
                  <div>
                    <h1 className="Polaris-Header-Title">{name}</h1>
                    <span>{formatLastSavedDate(updatedAt)}</span>
                  </div>
                  {"Hide payment method" === title ||
                  "Hide delivery method" === title ? (
                    <Button
                      onClick={() => {
                        if ("Hide payment method" === title) {
                          handleGoToPayment()
                        } else {
                          handleGoToShipping()
                        }
                      }}
                    >
                      To customizations page
                    </Button>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        marginLeft: 10,
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="OfferStatusMark"
                        style={{ backgroundColor: offerMarkColor }}
                      />
                      <OfferStatusButton
                        token={token}
                        shop={shop}
                        firebase={firebase}
                        state={state}
                        setState={setState}
                      />
                    </div>
                  )}
                </div>
                {hasUnsavedChanges && (
                  <Fragment>
                    <Badge>Unsaved Changes</Badge>
                    {false && (
                      <div
                        style={{
                          float: "right",
                          marginRight: "8em",
                        }}
                      >
                        <Button
                          onClick={handleFormSubmit}
                          primary
                          loading={isSubmitting}
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  ) : (
    <div style={{ padding: "10px 0" }}>
      <h1 className="Polaris-Header-Title" style={{ display: "inline" }}>
        {title ? title : "Create Custom Content"}
      </h1>
    </div>
  )
}

export default DocStatus
