import React, { useState, useCallback, useEffect } from "react"
import {
  Card,
  FormLayout,
  TextField,
  Button,
  Icon,
  Layout,
  Stack,
  Popover,
  ActionList,
  Modal,
  TextStyle,
  ButtonGroup,
  Form,
  Checkbox,
  ChoiceList,
  Link,
} from "@shopify/polaris"
import {
  ImageMajor,
  TextBlockMajor,
  ColumnWithTextMajor,
  HeaderMajor,
  DeleteMajor,
  ChevronUpMinor,
  ChevronDownMinor,
} from "@shopify/polaris-icons"
import update from "immutability-helper"
import Divider from '../../../components/divider'
import SmartRules from '../offers/smartRules'
import CreateOfferSteps from '../offers/createOfferSteps'
import DocSave from "../../../components/docSave"
const HidePaymentMethodsForm = ( props ) => {
const { token, host, shop,location, state, setState, productPickerShow, setProductPickerShow, currencySymbol, isCreating, isEditing, isSubmitting, hasUnsavedChanges, handleFormSubmit, handleCustomContentDelete } = props
  const addSmartRule =  () => {
    const conditions = {
      conditions:{
          all:[
              {
                  any: [
                      {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                  ]
              }
              ]
      },
      event: { 
          type: 'foundout',
          params: {
            message: 'rule has found out!'
          }
      }
  }
    setState(update(state, { triggers: { $set: conditions }}))
  }
  //const formValues = getValues(fields)
  /* The form layout, created using Polaris and App Bridge components. */
  return (
      <Layout>
        <Layout.Section>
          {isCreating && (
          <div style={{margin: '20px 0 10px -25px'}}>
            <CreateOfferSteps step={2}/>
          </div>
          )}
          <div style={{ paddingRight: '3rem', marginBottom: 50, }}>
            <Form 
                  disabled={
                    !state.name 
                  }
                  onSubmit={handleFormSubmit}>
            <FormLayout>
              <Card sectioned>
                <FormLayout>
                  <TextField
                    value={state.paymentMethodName}
                    label="Payment method"
                    requiredIndicator
                    onChange={(value) => setState(update(state, { paymentMethodName: { $set: value }}))}
                  />
                </FormLayout>
              </Card>
              <Card sectioned>
                <FormLayout>
                  <h6>
                  <TextStyle variation="strong">
                    Display conditions
                  </TextStyle>
                  </h6>
                  <SmartRules host={host} shop={shop} token={token} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currencySymbol={currencySymbol} type={"customization"} factsOptionsValue={[
                  { label: "Cart products", value: "cartProducts" },
                  { label: "Cart total", value: "cartTotal" },
                  { label: "Customer", value: "customer" },
                ]} />
                </FormLayout>
              </Card>
              
            </FormLayout>
            <DocSave isCreating={isCreating} isEditing={isEditing} isSubmitting={isSubmitting} hasUnsavedChanges={hasUnsavedChanges} handleFormSubmit={handleFormSubmit} handleCustomContentDelete={handleCustomContentDelete} location={location}/>
              </Form>
            </div>
        </Layout.Section>
       
      </Layout>
  )
}

export default HidePaymentMethodsForm