import { axiosInterceptor } from "./axiosInterceptor"

const getPaymentCustomization = (payload, token, shop, host) => {
  const axiosWithToken = axiosInterceptor(shop, host)
  return axiosWithToken.request({
    url: `/getPaymentCustomization`,
    method: "post",
    data: payload,
    headers: {
      "X-Shopify-Access-Token": token,
      "X-Shopify-Shop-Domain": shop,
    },
  })
}

const createPaymentCustomization = (payload, token, shop, host) => {
  const axiosWithToken = axiosInterceptor(shop, host)
  return axiosWithToken.request({
    url: `/createPaymentCustomization`,
    method: "post",
    data: payload,
    headers: {
      "X-Shopify-Access-Token": token,
      "X-Shopify-Shop-Domain": shop,
    },
  })
}

const updatePaymentCustomization = (payload, token, shop, host) => {
  const axiosWithToken = axiosInterceptor(shop, host)
  return axiosWithToken.request({
    url: `/updatePaymentCustomization`,
    method: "post",
    data: payload,
    headers: {
      "X-Shopify-Access-Token": token,
      "X-Shopify-Shop-Domain": shop,
    },
  })
}

const deletePaymentCustomization = async (id, shop, token) => {
  const query = {
    query: `mutation paymentCustomizationDelete($id: ID!) {
      paymentCustomizationDelete(id: $id) {
        deletedId
        userErrors {
          field
          message
        }
      }
    }`,
    variables: {
      id: `gid://shopify/PaymentCustomization/${id}`,
    },
  }
  const response = await fetch("/api/graphql", {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "Content-Type": "application/json",
      "x-shopify-shop-domain": shop,
      "x-shopify-access-token": token,
    },
  })
}

export {
  getPaymentCustomization,
  createPaymentCustomization,
  updatePaymentCustomization,
  deletePaymentCustomization,
}
