import React from "react"
import { ButtonGroup, Button } from "@shopify/polaris"
import { navigate } from "gatsby"
const DocSave = (props) => {
  const {
    isCreating,
    isEditing,
    location,
    handleFormSubmit,
    isSubmitting,
    hasUnsavedChanges,
    handleCustomContentDelete,
  } = props

  return (
    <div
      style={{
        marginTop: 50,
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "row-reverse",
        justifyContent: "flex-start",
      }}
    >
      <div>
        {isCreating && (
          <ButtonGroup>
            <Button
              onClick={() => handleFormSubmit("save")}
              primary
              loading={isSubmitting}
            >
              Save
            </Button>
            <Button submit primary loading={isSubmitting}>
              Save and publish
            </Button>
          </ButtonGroup>
        )}
        {isEditing && (
          <ButtonGroup>
            <Button
              destructive
              outline
              onClick={() => {
                handleCustomContentDelete()
              }}
            >
              Delete
            </Button>
            <Button submit primary loading={isSubmitting}>
              Save
            </Button>
          </ButtonGroup>
        )}
      </div>
      <div style={{ marginRight: 30 }}>
        {isCreating ? (
          <Button
            plain
            destructive={hasUnsavedChanges || isCreating}
            onClick={() => {
              navigate("/app/customizations", {
                state: location.state,
                replace: true,
              })
            }}
          >
            {hasUnsavedChanges ? "Discard Changes" : "Discard"}
          </Button>
        ) : (
          <Button
            plain
            destructive={hasUnsavedChanges || isCreating}
            onClick={() => {
              navigate("/app/customizations", {
                state: location.state,
                replace: true,
              })
            }}
          >
            {hasUnsavedChanges ? "Discard Changes" : "Back"}
          </Button>
        )}
      </div>
    </div>
  )
}

export default DocSave
